import React, { useEffect, useState } from 'react';

import { getAllBadges } from '../../../Services/badges';

import './badgecontent.css';


const ImageSlider = ({
    badges = {}
}) => {
    // Sample image URLs - replace these with your own images
    // const images = [
    //     'https://cntndrbucket.s3.us-east-2.amazonaws.com/staging/public/image/jpeg/845184532584-early-adopter-badge-cntndr.JPG',
    //     'https://cntndrbucket.s3.us-east-2.amazonaws.com/staging/public/image/jpeg/515001638749-Level1.JPG',
    //     'https://cntndrbucket.s3.us-east-2.amazonaws.com/staging/public/image/jpeg/117769414870-competitor-1.JPG',
    //     'https://cntndrbucket.s3.us-east-2.amazonaws.com/staging/public/image/jpeg/723813262337-Victor-2.JPG',
    // ];

    return (
        <div className="slider-container">
            <div className="column first-badge-container">

                {[...(badges?.first || [])].map((item, index) => (
                    <div className="image-wrapper" key={`down2-${index}`}
                        style={{ backgroundImage: `url(${item.badgeImage})`, borderRadius: 20 }}
                    >
                        <div className="badge-info-container">
                            <div className="badge-title" >{item.badgeName}</div>

                            <div className="badge-level">Level {index + 1} Badge</div>
                        </div>

                        {/* <img src={src} alt={`Slide ${index + 1}`} /> */}
                    </div>
                ))}
            </div>
            <div className="column second-badge-container">
                {[...(badges?.second || []), ...(badges?.second || [])].map((item, index) => (
                    <div className="image-wrapper" key={`down2-${index}`}
                        style={{ backgroundImage: `url(${item.badgeImage})`, borderRadius: 20 }}
                    >
                        <div className="badge-info-container">
                            <div className="badge-title" >{item.badgeName}</div>

                            <div className="badge-level">Level {index + 1} Badge</div>
                        </div>

                        {/* <img src={src} alt={`Slide ${index + 1}`} /> */}
                    </div>
                ))}
            </div>

            <div className="column first-badge-container hide-on-mobile">
                {[...(badges?.third || []), ...(badges?.third || [])].map((item, index) => (
                    <div className="image-wrapper" key={`down2-${index}`}
                        style={{ backgroundImage: `url(${item.badgeImage})`, borderRadius: 20 }}

                    >
                        <div className="badge-info-container">
                            <div className="badge-title" >{item.badgeName}</div>
                            <div className="badge-level">Level {index + 1} Badge</div>
                        </div>

                        {/* <img src={src} alt={`Slide ${index + 1}`} /> */}
                    </div>
                ))}
            </div>
        </div>
    );
};

const BadgeContent = props => {

    const [badges, setBadges] = useState({});

    useEffect(() => {
        const getBadges = async () => {
            try {
                const allBadges = await getAllBadges(null);

                if (allBadges.length > 8) {

                    const first = [allBadges[0], allBadges[1], allBadges[2]];
                    const second = [allBadges[3], allBadges[4], allBadges[5]];
                    const third = [allBadges[6], allBadges[7], allBadges[8]];
                    setBadges({
                        first: [...first, ...first],
                        second: [...second, ...second],
                        third: [...third, ...third],
                    });
                }

            } catch (err) {
                setBadges([]);
            }
        };

        getBadges();
    }, []);



    return <div className="badge-content-container">
        <div className='main-wrapper'>
            <div className='main-container badge-detail-container' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ paddingTop: 24 }}>
                    <div className="badge-detail-heading" style={{
                        // display: 'flex', 
                        // flexDirection: 'column',
                        // alignContent: 'flex-end', 
                        // height: '100%', 
                        // paddingTop: '30%',
                        //  paddingRight: 12 
                    }}>
                        <div>
                            <h5 style={{ color: 'white', marginTop: 24, color: '#05C6FE' }}>ELEVATE YOUR MUSIC THROUGH COMPETITION</h5>
                        </div>
                        <div>
                            <h1 style={{ color: 'white' }}>An eSport For Music Producers</h1>
                        </div>
                        <div>
                            <p style={{ color: 'white', marginTop: 24 }}>
                                Unlock special badges, earn Clout, win rewards, and collect trophies while battling other music producers around the world.
                            </p>
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%'}} className="main-badge-slider-container">
                    {badges?.first && badges?.second && badges?.third && <ImageSlider
                        badges={badges}
                    />}
                </div>
            </div>
        </div>
    </div >
};

export default BadgeContent;