import React from 'react';
import './index.css';

import AccessIcon from './icons/access.svg';
import BattleIcon from './icons/battle.svg';
import DiscoveryIcon from './icons/discovery.svg';
import MusicIcon from './icons/music.svg';
import RealtimeIcon from './icons/realtime.svg';
import Realtime2Icon from './icons/realtime2.svg';

const WhyCntndr = () => {
    return (


        <div className="cntndr-landing">
            <div className="hero">
                <div className="landing-header-text">Why <span className="cntndr-text">CNTNDR?</span></div>
                <p className="hero-subtitle">
                    Showcase your skills, connect with fellow creators, and take your music to the next level.
                </p>
            </div>

            <div className="features-container">
                <div className="features-row-container">

                    <div className="feature">
                        <div className="feature-icon">
                            <img src={DiscoveryIcon} style={{ width: 35, height: 35 }} />
                        </div>

                        <div className="feature-content">
                            <div className="feature-header">
                                <span>Discovery</span>
                            </div>
                            <p>
                                Gain exposure and increase your visibility as your tracks are discovered by industry professionals, influencers, and new fans.
                            </p>
                        </div>
                    </div>

                    <div className="feature">
                        <div className="feature-icon">
                            <img src={MusicIcon} style={{ width: 35, height: 35 }} />
                        </div>
                        <div className="feature-content">
                            <div className="feature-header">
                                <span>Grow your fan base</span>
                            </div>
                            <p>
                                Expand your reach and build a loyal following by showcasing your tracks in head-to-head battles that captivate new listeners.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="features-row-container">
                    <div className="feature">
                        <div className="feature-icon">
                            <img src={RealtimeIcon} style={{ width: 35, height: 35 }} />
                        </div>
                        <div className="feature-content">
                            <div className="feature-header">
                                <span>Real-time feedback</span>
                            </div>
                            <p>
                                Receive instant, actionable feedback from a community of peers and listeners, helping you refine your sound and elevate your craft.
                            </p>
                        </div>
                    </div>

                    <div className="feature">
                        <div className="feature-icon">
                            <img src={AccessIcon} style={{ width: 35, height: 35 }} />
                        </div>
                        <div className="feature-content">
                            <div className="feature-header">
                                <span>Access to community</span>
                            </div>
                            <p>
                                Join a vibrant community of like-minded producers where you can share experiences, exchange ideas, and support each other’s growth.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="features-row-container">
                    <div className="feature">
                        <div className="feature-icon">
                            <img src={Realtime2Icon} style={{ width: 35, height: 35 }} />
                        </div>
                        <div className="feature-content">
                            <div className="feature-header">
                                <span>Real-time feedback</span>
                            </div>
                            <p>
                                Connect with other talented producers for collaboration, unlocking creative potential and new avenues for your music.
                            </p>
                        </div>
                    </div>

                    <div className="feature">
                        <div className="feature-icon">
                            <img src={BattleIcon} style={{ width: 35, height: 35 }} />
                        </div>
                        <div className="feature-content">
                            <div className="feature-header">
                                <span>Battle test your music</span>
                            </div>
                            <p>
                                Put your music to the ultimate test in competitive battles, proving your skills and refining your tracks in a high-stakes environment.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyCntndr;