import React, { useContext, useEffect, useRef, useState } from 'react'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Button } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import { openBattleschema } from '../../ValidationSchema/validationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import useHeader from '../../Helper/useHeader';
import * as cs from '../../Api/cs'
import { toast } from 'react-toastify';
// import { ImageIcon } from '../../Component/Icons';
import Form from 'react-bootstrap/Form';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './OpenBattleModal.scss';
import { SocketContext } from '../../Context/socket';
import { useDispatch, useSelector } from 'react-redux';
// import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import "../../../node_modules/react-select/dist/react-select.cjs"
import { BallTriangle } from 'react-loader-spinner';
import { updateUserData } from '../../Redux/Slices/user.slice';
import defaultUserImage from '../../assets/images/default.png'

import { AnalyticsSendEvent } from '../../Helper/Analytics';

const OpenBattleModal = ({ showOpenBattleModal, trackInfo, setShowModal, setShowOpenBattleModal, battleOption, getBattleList, handleOpenBattleClose, addToPlaylist, opponent = null }) => {

    const headers = useHeader()
    const socket = useContext(SocketContext)



    const dispatch = useDispatch()

    const [oppononet, setOpponent] = useState(null)
    const [genreList, setGenreList] = useState([]);
    const [searchList, setSearchList] = useState([])
    const [selectedOpponent, setSelectedOpponent] = useState('');
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [tag, setTag] = useState([]);
    const [tagValidationError, setTagValidationError] = useState('');
    const [image, setImage] = useState(null)

    const tagRef = useRef()

    const { userId, userInfo } = useSelector(state => state?.user)
    const getGenreList = async () => {
        const res = await cs.get('list', 'genre', null, headers)
        setGenreList(res?.data?.data)

    }

    const getUserData = async () => {
        const response = await cs.get("getUserList/" + userId, 'users', {}, headers)
        dispatch(updateUserData(response?.data?.data))
    }

    const getOpponentData = async () => {
        const response = await cs.get('getUserList/' + opponent, 'users', {}, headers)

        setSelectedOpponent('challenge');
        setValue('selectedOption', 'challenge');
        setValue('opponentSearch', response?.data?.data?.name);
        setOpponent(response?.data?.data)
    }
    useEffect(() => {
        getGenreList()
        getUserData()
        if (opponent != null || opponent != undefined) {
            getOpponentData()
        }
        if (battleOption == 'addNewTrack') {
            setImage(URL.createObjectURL(trackInfo?.track_image))
        }
    }, [])
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
        register,
        watch,
        trigger,
        getValues,
    } = useForm({
        resolver: yupResolver(openBattleschema),
        mode: 'onChange',
        defaultValues: {
            descriptionInput: trackInfo?.track_description || ''
        }


    });

    const selectedOption = watch('selectedOption', '');

    useEffect(() => {
        setValue('selectedOption', 'open');
    }, [setValue]);

    useEffect(() => {
        if (watch('tag')) {
            trigger('tag');
        }
    }, [watch('tag')]);


    useEffect(() => {
        if (trackInfo?.track_tags) {
            const defaultTags = trackInfo?.track_tags
                .map(item => {
                    return {
                        label: item,
                        value: item
                    }
                });
            setTag(defaultTags);
            setValue('tag', trackInfo?.track_tags);
        }
    }, [trackInfo]);


    const onSubmit = async (data) => {
        setLoading(true)

        let upload_data = {}
        let track_response = {};

        if (battleOption == 'addNewTrack') {


            const imgFormObj = new FormData()
            imgFormObj.append("data", trackInfo?.track_image)


            const videoFormObj = new FormData()
            videoFormObj.append("data", trackInfo?.track_video)


            const image = await cs.post('upload', 'util', imgFormObj, headers)
            const video = await cs.post('upload', 'util', videoFormObj, headers)

            // const [image, video] = await Promise.all([
            //     cs.post('upload', 'util', imgFormObj, headers),
            //     cs.post('upload', 'util', videoFormObj, headers)
            // ]);
            // upload_data.track_image = trackInfo?.image
            // upload_data.track_video = trackInfo?.audio

            upload_data.track_image = image?.data?.data
            upload_data.track_video = video?.data?.data
            upload_data.track_name = trackInfo?.track_name
            upload_data.track_genre = trackInfo?.track_genre
            upload_data.duration = trackInfo?.duration
            upload_data.track_description = data?.descriptionInput
            upload_data.track_tags = data?.tag
            upload_data.status = 'active'

            if (addToPlaylist === true) {

                upload_data.track_add = 1
            }


            track_response = await cs.post('add', 'track', upload_data, headers);

            AnalyticsSendEvent({
                category: "Track",
                action: "upload_track",
                label: "Upload Track"
            });
        }



        const _data = {
            "battleTrackName": battleOption == 'addNewTrack' ? track_response?.data?.data?._id : trackInfo?._id,
            "battleDescription": data?.descriptionInput,
            "battleTrackType": data?.selectedOption == 'open' ? 1 : 0,
            "battleTrackGenre": battleOption == 'addNewTrack' ? track_response?.data?.data?.track_genre : trackInfo?.track_genre?._id,
            "battleEnteryFee": data?.entryFee,
            "tags": data?.tag,
            "battlecreatedBy": userId,
            "opponentcreatedBy": "",
            "battleTrackOpponent": "",
            "challengeOpponentUserId": oppononet?._id,

        }

        const response = await cs.post('add', 'battel', _data, headers)

        if (response?.data?.code == 200) {

            AnalyticsSendEvent({
                category: "Battle",
                action: "start_battle",
                label: "Start Battle"
            });


            setLoading(false)
            toast.success(response?.data?.message);
            if (data?.selectedOption === 'challenge') {
                const _data =
                {
                    userId: userId,
                    opponentId: oppononet._id,
                    battleId: response?.data?.data?._id
                }
                if (oppononet) {
                    socket.emit("battle", _data)
                }
            }
            getUserData()

        }
        else {
            setLoading(false)
            toast.error("There is some error in battle creation")
        }
        setShowOpenBattleModal(false)
        getBattleList()
    };

    const handleOptionChange = (option) => {
        setValue('selectedOption', option);
        if (option === 'open') {
            setValue('opponentSearch', '');
        }
    };

    const handleBackTrack = () => {
        setShowModal(true)
        setShowOpenBattleModal(false)
    }


    const handleOpponentSearch = async (e) => {


        const response = await cs.get('auto/' + e.target.value, 'users', {}, headers)
        setSearchList(response?.data?.data)
    }



    const handleOpponentSelect = (opponent) => {
        setSelectedOpponent('challenge');
        setValue('opponentSearch', opponent?.name);
        setOpponent(opponent)
        setSearchList([])
            ;
    }




    useEffect(() => {
        if (inputValue.endsWith(" ")) {
            if (inputValue?.trim() !== '') {
                const newTag = { label: inputValue.trim(), value: inputValue.trim() };
                const updatedTags = [...tag, newTag];
                setTag(updatedTags);
                setValue('tag', updatedTags.map(tag => tag.value));
                setInputValue('');
            }
        }
    }, [inputValue])

    // Function to handle key down events
    // const handleKeyDown = (event) => {
    //     const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    //     if ((event?.key === ' ' || (isMobile && event?.key === 'Spacebar')) && inputValue?.trim() !== '') {
    //         // If the user presses space and the input is not empty
    //         const newTag = { label: inputValue?.trim(), value: inputValue?.trim() };
    //         const updatedTags = [...tag, newTag];
    //         setTag(updatedTags);
    //         setValue('tag', updatedTags?.map(tag => tag?.value)); 
    //         setInputValue('');
    //     }
    // };


    // const handleKeyDown = (event) => {

    //     const isSpacebar = event.key === ' ' || event.keyCode === 32 || inputValue === ' ';
    //     if (isSpacebar && inputValue?.trim() !== '') {
    //         const newTag = { label: inputValue.trim(), value: inputValue.trim() };
    //         const updatedTags = [...tag, newTag];
    //         setTag(updatedTags);
    //         setValue('tag', updatedTags.map(tag => tag.value));
    //         setInputValue('');
    //     }
    // };


    return (
        <Modal show={showOpenBattleModal} onHide={handleOpenBattleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Start A Battle</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {loading && ( // Show loader if loading state is true
                    <div className="loader-container">
                        <div className="loader">
                            <BallTriangle
                                type="TailSpin"
                                color="#00BFFF"
                                height={50}
                                width={50}
                            />
                        </div>
                    </div>
                )}
                <div className="w-100">

                    <div className="track-details-wrapper">
                        <div className="track-thumb-wrap">
                            {battleOption == "chooseFromMyTrack" && <img src={trackInfo?.track_image} />}
                            {battleOption == "addNewTrack" && <img src={image} />}
                        </div>

                        <div className="track-info-detail">
                            <div className='track-author-name'>{trackInfo?.createdBy?.name ? trackInfo?.createdBy?.name : (userInfo?.name ? userInfo?.name : userInfo?.userName)}</div>
                            <div className='track-name'> {trackInfo?.track_name}</div>

                        </div>
                        <div className='track-time-wrap'>{trackInfo?.duration}</div>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='openbattle-wrapper'>
                            <Form.Group className="form-row">
                                <Form.Control type="text" placeholder='Description' id="descriptionInput" {...register('descriptionInput')} />
                                {errors?.descriptionInput && <div className="errorMsg">{errors?.descriptionInput?.message}</div>}
                            </Form.Group>


                            <Form.Group className="form-row react-select">
                                <CreatableSelect
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    inputValue={inputValue}
                                    isClearable
                                    isMulti
                                    {...register('tag')}
                                    menuIsOpen={false}
                                    onChange={(selectedTags, actionMeta) => {
                                        const selectedTagValues = selectedTags.map(tag => tag.value);
                                        setTag(selectedTags);
                                        setValue('tag', selectedTagValues);
                                    }}
                                    onInputChange={(newValue) => setInputValue(newValue)}
                                    // onKeyDown={handleKeyDown}
                                    placeholder="Tags (3)"
                                    value={tag}
                                    ref={tagRef}

                                />
                                {errors?.tag && <p className="errorMsg">{errors?.tag?.message}</p>}
                            </Form.Group>

                        </div>

                        <div className='open-battle-bottom-wrapper'>
                            <Form.Group className="form-row tabs-wrap">

                                <div className='tab-group-wrap'>
                                    <div className={`tab-link ${selectedOption === 'open' ? 'active' : ''}`} onClick={() => handleOptionChange('open')}>Open Battle</div>
                                    <div className={`tab-link ${selectedOption === 'challenge' ? 'active' : ''}`} onClick={() => handleOptionChange('challenge')}>Challenge Battle</div>
                                </div>

                            </Form.Group>
                            <Form.Group className="form-row">
                                <Form.Label htmlFor="entryFee">Entry Fee</Form.Label>
                                <div className='w-100 position-relative'>
                                    <Form.Control type="number" placeholder='0' id="entryFee" {...register('entryFee')} max={userInfo?.clout}
                                        onChange={(e) => {
                                            const enteredValue = parseInt(e.target.value);

                                            // Validate if entered value is greater than 0
                                            if (enteredValue <= 0) {
                                                e.target.setCustomValidity('Entry Fee must be greater than 0');
                                            } else {
                                                e.target.setCustomValidity('');
                                            }
                                            if (enteredValue > userInfo?.clout) {
                                                e.target.value = userInfo?.clout;
                                            }
                                        }} />
                                    <div className='currency-wrap'>Clout</div>
                                </div>
                                {errors?.entryFee && <div className="errorMsg">{errors?.entryFee?.message}</div>}
                            </Form.Group>
                            {selectedOption === 'challenge' && (
                                <div className="search-component">
                                    <div className='w-100 position-relative'>
                                        <Form.Control type="text" id="opponentSearch" placeholder="Find your opponent" {...register('opponentSearch')} defaultValue={selectedOpponent} onChange={handleOpponentSearch} />
                                        <button className='btn' type="button">
                                            <FontAwesomeIcon icon={faSearch} />
                                        </button>
                                    </div>
                                    {errors?.opponentSearch && <div className="errorMsg">{errors?.opponentSearch?.message}</div>}
                                    {console.log("searchList", searchList)}
                                    {searchList && searchList?.length > 0 &&
                                        <div className="search-list-dropdown">
                                            {searchList?.map((item) => (
                                                userId !== item?.id && (
                                                    <div key={item?._id} className="search-item" onClick={() => handleOpponentSelect(item)}>
                                                        <img src={item?.image ? item.image : defaultUserImage} alt={`${item?.name}'s profile`} className="search-picture" />
                                                        {item?.name}
                                                    </div>
                                                )
                                            ))}
                                        </div>

                                    }
                                </div>
                            )}

                            <div className='open-battle-btn-wrap'>

                                <Button variant='' className='back-btn' type='button' onClick={handleBackTrack}>Back to track selection</Button>


                                <Button variant='primary' type="submit">Start Battle</Button>
                            </div>
                        </div>
                    </form>

                </div>
            </Modal.Body>

        </Modal>
    )
}

export default OpenBattleModal
