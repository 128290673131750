import React from 'react';
import { Button } from 'react-bootstrap';
import './index.scss';


const BattleAndRiseUp = () => {
    return (
        <div className="badge-content-container">
            <div className='main-wrapper'>
                <div className="battle-content-main-container">
                    <div className="info-title">
                        BATTLE AND RISE UP THE RANKS
                    </div>
                    <div className="info-paragraph">
                        Join the leading global community of music creators to battle it out on the main stage! Showcase your talent, top the leaderboard, and earn Clout and recognition while connecting with others along the way.
                    </div>

                    <div className="action-container">
                        <div className='bottom-btn-wrap'>
                            <Button variant='light' onClick={() => { }}>Login</Button>
                            <Button variant='primary' onClick={() => { }}>Get Started</Button>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
};

export default BattleAndRiseUp;