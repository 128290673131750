import React from 'react';

import './footer.css';

const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling effect
    });
  };

const Footer = () => {

    const year = new Date().getFullYear();


    return <div style={style.footer}>
        <div className="footer-container">
            <div style={{ color: '#FFFFFF99' }}>
            © {year} CNTNDR, Inc.
            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                gap: 10
            }}>
                <div style={style.footerLinkContainer}>
                    <a onClick={scrollToTop} href="#/legal" style={style.links}>Terms & Conditions</a>
                </div>
                <div style={style.footerLinkContainer}>
                    <a onClick={scrollToTop} href="#/legal?menu=privacy_policy" style={style.links}>Privacy Policy</a>
                </div>
                <div style={style.footerLinkContainer}>
                    <a onClick={scrollToTop} href="#/legal?menu=community_guidelines" style={style.links}>Community Guidelines</a>
                </div>
            </div>
        </div>
    </div>
};

const style = {
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingTop: 24,
        paddingBottom: 24,
        marginTop: 15,
        marginBotttom: 24
    },
    footerLinkContainer: {
        paddingLeft: 12, paddingRight: 12
    },
    links: { 
        color: '#FFFFFF99', 
        textDecoration: 'none' 
    }
}

export default Footer;