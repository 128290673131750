import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { socket } from '../../Context/socket'

// import { updateSearch } from '../../Redux/Slices/search.slice'

// import EndingBattles from '../../Component/Battles/EndingBattles'
import JoinBattles from '../../Component/Battles/JoinBattles'
import RecentWinners from '../../Component/Battles/RecentWinners'
import RegistrationBottomUser from '../../Component/RegistrationBottomUser/RegisterBottomUser'

import Header from '../../Component/Header/Header'
import Sidebar from '../../Component/Sidebar/Sidebar'

import Announcement from './Announcement/Announcement'
import HomeBattleSlider from './HomeBattleSlider/HomeBattleSlider'
import HomeBanner from '../Home/HomeBanner/HomeBanner'
import PopularGenres from './PopularGenres'
import BadgeContent from './BadgeContent';
import WhyCntndr from './WhyCntndr';
import BattleAndRiseUp from './BattleAndRiseUp';

// import LeaderBoards from '../Home/LeaderBoards/LeaderBoards'
// import HomeRegistration from '../Home/HomeRegistration/HomeRegistration'
import PopularArtist from '../../Component/PopularArtist/PopularArtist'
import TrendingBeats from '../../Component/TrendingBeats/TrendingBeats'
import Chat from '../../Component/OpenChat/OpenChat'
import RegistrationBottom from '../../Component/RegistrationBottom/RegistrationBottom'
import BottomBar from '../../Component/BottomBar/BottomBar'


import Footer from '../../Component/Footer';

import '../Home/Home.scss'

const HomeV2 = () => {
  const { userId } = useSelector(state => state.user);

  const { search } = useSelector(state => state.search)
  const dispatch = useDispatch()

  useEffect(() => {

    if (search) {
      //   dispatch(updateSearch(''))
    }
    scrollToTop()
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    // socket.on("create_socket", (data) => {
    //   if (data) {
    //     localStorage.setItem("socketId", data.socketId)
    //     if (userId && (userId !== undefined || userId !== "")) {
    //       const socketData = {
    //         userId: userId,
    //         socketId: localStorage.getItem("socketId")
    //       }
    //       socket.emit("updateSocketId", socketData);
    //     }
    //   }
    // })
    // if (userId && (userId !== undefined || userId !== "")) {
    //   const socketData = {
    //     userId: userId,
    //     socketId: localStorage.getItem("socketId")
    //   }
    //   socket.emit("updateSocketId", socketData);
    // }
  }, [socket, userId])



  return (
    <>
      <Sidebar />
      <Header />
      <div className="main-content" >
        {/* Need Condition when Loggedin */}
        {userId &&
          <Announcement />
        }
        {!userId && <HomeBanner />}
        {!userId && <WhyCntndr />}


        <HomeBattleSlider />


        <div className='home-battles-wrapper'>
          <div className='main-wrapper'>
            <div className='main-container'>
              <RecentWinners />
            </div>
          </div>
        </div>


        {!userId && <BadgeContent />}
        
        <div className='home-battles-wrapper'>
          <div className='main-wrapper'>
            <div className='main-container'>
              <JoinBattles />
            </div>
          </div>
        </div>




        {!userId && <BattleAndRiseUp />}
        <PopularGenres />
        <TrendingBeats />

        <PopularArtist />


        <Chat />
        {!userId ? <RegistrationBottom /> : <RegistrationBottomUser />}
        <BottomBar /> *
      </div>

    </>
  )
}

export default HomeV2;
