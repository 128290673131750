import React, { useEffect, useState, useRef } from 'react'

import { Button } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import './battles.scss'

import BattleCard from '../BattelCard/BattleCard'

import { ViewAllIcon } from '../Icons'
import useHeader from '../../Helper/useHeader'
import { useDispatch, useSelector } from 'react-redux'
import * as cs from '../../Api/cs'
import { useNavigate } from 'react-router-dom'
import { updateBattleId } from '../../Redux/Slices/battle.slice'
import StartBattleModal from '../../Modal/StartBattleModal/StartBattleModal'
import OpenBattleModal from '../../Modal/OpenBattleModal/OpenBattleModal'
import ReportModal from '../../Modal/ReportModal/ReportModal'
import Login from '../../Pages/Login/Login'
import SignUp from '../../Pages/SignUp/SignUp'
import OtpModal from '../../Modal/OtpModal'
import EmailLinkForPassword from '../../Pages/EmailLinkForPassword/EmailLinkForPassword'
import SetUpAccount from '../../Modal/SetUpAccount/SetUpAccount'

import { NextArrow, PreviousArrow } from '../Icons';

const RecentWinners = ({ genre = null }) => {

    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const [showModal, setShowModal] = useState(false);
    const [showOpenBattleModal, setShowOpenBattleModal] = useState(false);
    const [trackInfo, setTrackInfo] = useState('');
    const [battleOption, setBattleOption] = useState('')
    const [battleList, setBattleList] = useState([])
    const [addToPlaylist, setAddToPlaylist] = useState(false);
    const [sort, setSort] = useState('dsc')
    const [filter, setFilter] = useState('abcd')
    const headers = useHeader()
    const [awaitingBattle, setAwaitingBattle] = useState([])
    const [showLogin, setShowLogin] = useState(false);
    const [showOtpModal, setShowOtpModel] = useState(false)
    const [emailLinkModal, setEmailLinkModal] = useState(false)
    const [showSignUp, setShowSignUp] = useState(false)
    const [showSetUp, setShowSetUp] = useState(false)
    const [user, setUser] = useState(null)
    const [id, setId] = useState(null)
    const [token2, setToken2] = useState(null)

    // const socket = useContext(SocketContext)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { userId } = useSelector(state => state.user)

    const handleOpenBattleClose = () => {
        setShowOpenBattleModal(false);
    };

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    const handleLoginClose = () => {
        setShowLogin(false)
    }
    const handleOtpClose = () => {
        setShowOtpModel(false)
    }
    const handleSignUpClose = () => {
        setShowSignUp(false)
    }

    const handleSetUpClose = () => {
        setShowSetUp(false)
    }
    const handleEmailLinkClose = () => {
        setEmailLinkModal(false)
    }



    useEffect(() => {
        getBattleList()
        // if (genre == null) {
        //     getAwaitingBattle()
        // }
        // else if (genre != null) {
        //     getAwaitingBattleOfGenre()
        // }
    }, [])

    const getBattleList = async () => {
        const response = await cs.get('recentWinners', 'battel', {}, headers)
        setBattleList(response?.data?.data)

    }

    const getAwaitingBattle = async () => {
        // const response = await cs.get('still/notjoin', 'battel', null, headers)
        // setAwaitingBattle(response?.data?.data.slice(0, 10))
    }

    const getAwaitingBattleOfGenre = async () => {
        // const response = await cs.get('awaitingBattlesOfGenre/' + genre, 'battel', null, headers)
        // setAwaitingBattle(response?.data?.data.slice(0, 10))
    }

    const handleBattleCard = (item) => {
        // dispatch(updateBattleId(item))
        // navigate(`/battle/${item}`, { state: { itemData: item, battles: awaitingBattle } })
    }

    // const handleCreateBattle = async () => {
    //     if (userId != null) {
    //         handleShow()
    //     }
    //     else if (userId == null) {
    //         setShowLogin(true)
    //     }
    // }


    // const usersAwaitingBattle = userId ? awaitingBattle?.filter(item => {
    //     return item.battlecreatedBy._id !== userId
    // }) || [] : awaitingBattle;


    const initSwiper = (swiper) => {
        // Here, we set the navigation buttons before Swiper initializes
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.params.navigation.nextEl = nextRef.current;
        swiper.navigation.init();
        swiper.navigation.update();
    }


    console.log('battleList',battleList)
    return (
        <>
            {battleList && battleList.length > 0 &&
                <div className='battles-container join-battles'>
                    <div className='battles-title-wrapper'>
                        <div className='battles-title'>Recent Winners</div>

                        <button className='btn' ref={prevRef} style={{ borderRadius: '50%' }}><PreviousArrow /></button>
                        <button className='btn' ref={nextRef} style={{ borderRadius: '50%' }}><NextArrow /></button>
                        {/* <div className='btn-wrap' ><Button variant='secondary' onClick={() => {
                            navigate(`/genre/${genre ? genre : 'all'}/join-battle?type=battle`, {
                                state: {
                                    type: "battle",
                                    title: "Join A Battle",
                                    ...(genre !== null && { genre })
                                }
                            });
                        }}>View all</Button>
                            <Button variant='primary' type='button' onClick={handleCreateBattle}>Create Battle</Button></div> */}
                    </div>



                    <div className='battle-slider'>



                        <Swiper
                            spaceBetween={20}
                            modules={[Navigation]}
                            navigation={{
                                prevEl: prevRef.current,
                                nextEl: nextRef.current,
                            }}
                            onBeforeInit={initSwiper}

                            breakpoints={{
                                1500: {
                                    slidesPerView: 3
                                },
                                1250: {
                                    slidesPerView: 3
                                },
                                768: {
                                    slidesPerView: 2

                                },
                                300: {
                                    enabled: false
                                }
                            }}

                        >
                            {battleList.length > 0 ?
                                battleList.map((item) =>
                                (

                                    <SwiperSlide key={item?._id}
                                        onClick={() => {
                                            // handleBattleCard(item?._id); 
                                            // dispatch(updateShowChat(false)); 
                                            // dispatch(updateWalletShow(false)); 
                                            // dispatch(updateIsBadgeInfo(false)); dispatch(updateOpenDirectly(false)) 
                                        }}>

                                        <div onClick={() => { handleBattleCard(item?._id) }}>
                                            <BattleCard battleId={item?._id} type={item?.battleOpponentStatus ? null : 'awaiting'} />
                                        </div>
                                    </SwiperSlide>


                                )) :
                                <div style={{ color: "white" }}>No Battles to Join</div>
                            }

                        </Swiper>


                    </div>
                    <div className='viewall-btn-wrap' onClick={() => {
                        // navigate(`/genre/${genre ? genre : 'all'}/join-battle`, {
                        //     state: {
                        //         type: "battle",
                        //         title: "Join A Battle",
                        //         ...(genre !== null && { genre })
                        //     }
                        // });
                    }}><Button variant='secondary'><ViewAllIcon /> View all</Button></div>
                </div>
            }

        </>
    )
}

export default RecentWinners
