import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';


import './PopularGenres.scss'

import useHeader from '../../../Helper/useHeader';

import { getGenreList } from '../../../Services/genres'
const PopularGenres = () => {
    const navigate = useNavigate()
    const headers = useHeader();
    const { userId } = useSelector(state => {
        console.log('stateeee', state)
        return state.user
    });

    const [genres, setGenres] = useState([]);
    useEffect(() => {
        getGenres();
    }, []);

    const getGenres = async () => {

        const response = await getGenreList(headers);
        const genreWithImages = response.filter(item => item.image)
        setGenres(genreWithImages.slice(0, 8))

    }

    console.log('genresss', genres)
    return (
        <div className="popular-genres-wrapper-v2" >
            <div className='main-wrapper'>
                <div className='main-container'>
                    {/* {userId && <h1>Recommended Battles</h1>} */}
                    <div className='popular-btn-wrap'>
                        <div className='popular-title'>Popular Genres</div>

                        <div className='btn-wrap' >
                            <Button variant='secondary' onClick={() => {

                            }} style={{ marginTop: 12 }}>
                                View all</Button>
                        </div>
                    </div>

                    <div className='popular-genres-wrapper-v2'>
                        <div className='popular-genres-content-v2'>


                            <div className='popular-genres-container'>

                                {genres.map(item => {
                                    return <div className='pg-tag-v2'
                                        onClick={() => {
                                            navigate(`/genre/${item?._id}`);
                                        }}

                                        style={{

                                            backgroundImage: `url("${item.image}")`,

                                        }}>
                                        <div className="genre-label"> {item.label}</div>

                                    </div>

                                })}



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopularGenres