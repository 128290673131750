import React, { useEffect, useState } from 'react'
import PlaceHolderImg from '../../../assets/images/announcement-bg.png'
import Gradient from '../../../assets/images/gradient.svg'

import { Button } from 'react-bootstrap'
import { Pagination, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './Announcement.scss'
import * as cs from '../../../Api/cs'
import useHeader from '../../../Helper/useHeader'
import { useNavigate } from 'react-router-dom'
import HomeBanner from '../../Home/HomeBanner/HomeBanner'
import { useDispatch } from 'react-redux'
import { updateShowChat } from '../../../Redux/Slices/chat.slice'
import { updateWalletShow } from '../../../Redux/Slices/modal.slice'

const Announcement = () => {
    const [announcement, setAnnoucement] = useState([])
    const headers = useHeader()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        getAnnoucementList()
    }, [])

    const getAnnoucementList = async () => {
        const response = await cs.get('getannouncementforuser', 'announcement', null, headers)

        setAnnoucement(response?.data?.data)

    }

    const handleDetail = (item) => {
        if (item?.openLink === 'sameTab') {
            const path = new URL(item?.link).hash.substring(1); // Extracting the path part after '#'
            navigate(path);

        } else if (item?.openLink === 'newTab') {
            window.open(item?.link, '_blank')
        }
    }

    return (
        <>
            {announcement && announcement.length > 0 ? <div className='announcement-wrap-v2'>
                <div className='gradient'>
                    <img src={Gradient} alt='' />
                </div>
                <div className='main-wrapper'>
                    <div className='main-container'>
                        <div className='announcement'>
                            <Swiper
                                loop={true}
                                modules={[Pagination, A11y, Autoplay]}
                                pagination={{ clickable: true }}
                                onSwiper={(swiper) => console.log(swiper)}
                                onSlideChange={() => console.log('slide change')}
                                centeredSlides={true}
                                spaceBetween={30}
                                breakpoints={{
                                    1500: {
                                        slidesPerView: 1.3
                                    },
                                    1250: {
                                        slidesPerView: 1.3
                                    },

                                    300: {
                                        slidesPerView: 1
                                    }
                                }}

                            >
                                {announcement && announcement.length > 0 && announcement.map((item) => (
                                    <SwiperSlide className='announcement-box-v2' >
                                        <div className='clickable-wrap' onClick={() => {
                                            dispatch(updateShowChat(false));
                                            dispatch(updateWalletShow(false))
                                        }}>

                                            <img style={{ cursor: 'pointer', borderRadius: 12, width: '100%' }}
                                                onClick={() => {
                                                    handleDetail(item)
                                                }} src={item?.image ? item?.image : PlaceHolderImg} alt='' />

                                            <div className='announcement-info-wrap'>
                                                <div className='announcement-info'>
                                                    <div className='announcement-title'>{item?.headline}</div>
                                                    <div className='announcement-description'>{item?.text}</div>
                                                </div>

                                                {item?.link && item?.isButtonVisible === 'Yes' &&
                                                    <div className='detail-btn-wrap'>
                                                        <Button variant='primary'
                                                            onClick={() => {
                                                                handleDetail(item)
                                                            }}>
                                                            {item?.buttonTitle || 'Details'}
                                                        </Button>
                                                    </div>}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}



                            </Swiper>
                        </div>
                    </div>
                </div>
            </div >
                :
                <HomeBanner />
            }
        </>
    )
}

export default Announcement