import React, { useEffect, useState } from 'react'
import AuthorOne from '../../assets/images/author-1.png';
import AuthorTwo from '../../assets/images/author-2.png';
import CrownIcon from '../../assets/images/icons/crown.svg';
import VodetIcon from '../../assets/images/icons/voted.svg';
import moment from 'moment';
import './BattleCard.scss'
import FireIcon from '../../assets/images/icons/fire-icon.svg';
import { ColoredStarIcon, StarIcon, VSIcon } from '../Icons';
import { Button } from 'react-bootstrap';
import * as cs from '../../Api/cs'
import useHeader from '../../Helper/useHeader';
import UserImg from '../../assets/images/default.png'
import AwaitingOppImg from '../../assets/images/awaitingOpp.png'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateBattleId } from '../../Redux/Slices/battle.slice';

import ClockSvg from './icons/clock.svg';
import CloutSvg from './icons/clout.svg';
import RewardSvg from './icons/reward.svg';
import MusicSvg from './icons/music.svg';

const BattleCard = ({ battleId, type = null, trending = false }) => {
    const headers = useHeader()
    const [battleDetail, setBattleDetail] = useState({})
    const [winner, setWinner] = useState()
    const [timer, setTimer] = useState('')
    const [danger, setDanger] = useState(null)
    const [vote1, setVote1] = useState(false)
    const [vote2, setVote2] = useState(false)
    const [challengeOpponentUser, setChallengeOpponentUser] = useState(null);

    const { userId } = useSelector(state => state.user)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        getBattleDetail()
    }, [])

    const getBattleDetail = async () => {
        let response = await cs.get('getById/' + battleId, 'battel', {}, headers)
        setBattleDetail(response?.data?.data[0])

        if (response?.data?.data[0]?.challengeOpponentUserDetails && response?.data?.data[0].battleTrackType === 0) {
            setChallengeOpponentUser(response?.data?.data[0]?.challengeOpponentUserDetails[0])
        }


        getVoteDetails(response?.data?.data[0])
        if (response?.data?.data[0]?.winnerId) {
            setWinner(response?.data?.data[0]?.winnerId)
        }
        if (battleDetail?.battleEndDate || response?.data?.data[0]?.battleEndDate) {
            calculateTimeRemaining(new Date(battleDetail?.battleEndDate || response?.data?.data[0]?.battleEndDate));
            const intervalId = setInterval(() => calculateTimeRemaining(new Date(battleDetail?.battleEndDate || response?.data?.data[0]?.battleEndDate)), 1000);
            return () => clearInterval(intervalId);
        }
    }

    const getVoteDetails = async (battleInfo) => {
        try {
            const response = await cs.get(`vote/${userId}/${battleId}`, 'battleVote', {}, headers)
            if (response?.data?.code == 200) {
                if (battleInfo?.battlecreatedBy === response?.data?.data?.votefor) {
                    setVote1(true)
                }
                else if (battleInfo?.opponentcreatedBy === response?.data?.data?.votefor) {
                    setVote2(true)
                }

            }
        }
        catch (error) {
        }

    }

    const calculateTimeRemaining = (endDate) => {
        const currentTime = Date.now();
        const time = endDate > currentTime ? endDate - currentTime : 0;

        let days = Math.floor(time / (1000 * 60 * 60 * 24));
        let hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((time % (1000 * 60)) / 1000);
        let dangerHours = days * 24 + hours + minutes / 60 + seconds / 3600;

        let formattedTime = days >= 1
            // ? `${days} ${days > 1 ? 'Days' : 'Day'}  ${hours} Hours ${minutes} Minutes`
            ? `${days} ${days > 1 ? 'Days' : 'Day'}  ${hours} hrs`
            : `${hours} ${hours > 1 ? 'Hrs' : 'Hr'} ${minutes} Min ${seconds} Sec`;
        if (time == 0) {
            setTimer(moment(endDate).format('MMMM DD, YYYY'))
        }
        else {

            setTimer(formattedTime);
            setDanger(dangerHours)
        }
    };

    const handleVote = () => {
        navigate(`/battle/${battleId}`, { state: { itemData: battleId } })
        dispatch(updateBattleId(battleId))
    }
    return (
        <>
            <div className='battle-card-wrapper cursor-pointer'>
                <div className='battle-wrapper-top'>

                    <div className='battle-author-wrap' >

                        <div className='battle-author-img-wrap left'>
                            {winner && winner?._id === battleDetail?.battlecreatedBy &&
                                <div className='battle-winner'><img src={CrownIcon} alt='Crown' /> Winner</div>
                            }
                            {battleDetail?.isTie && <div className='battle-tied '> Tied</div>}
                            {vote1 && <div className='voted'><img src={VodetIcon} alt='Voted' /></div>}
                            <img className={`${winner && winner?._id === battleDetail?.battlecreatedBy ? 'winner-border' : ''}`} src={battleDetail?.battleUsersDetails?.[0]?.image ? battleDetail?.battleUsersDetails?.[0]?.image : UserImg} />
                        </div>


                        <div className='battle-author-detail'>
                            <div className='author-name'>{battleDetail?.battleUsersDetails?.[0]?.name ? battleDetail?.battleUsersDetails?.[0]?.name : battleDetail?.battleUsersDetails?.[0]?.userName}</div>
                            {
                                battleDetail?.battleOpponentStatus === true &&
                                <div className='author-song-title'>{battleDetail?.trackDetails?.[0]?.track_name}</div>
                            }
                        </div>
                    </div>
                    <div className='battle-author-wrap'>
                        <div className='battle-author-img-wrap right'>
                            {winner && winner?._id == battleDetail?.opponentcreatedBy &&
                                <div className='battle-winner'><img src={CrownIcon} alt='Crown' /> Winner</div>
                            }
                            {battleDetail?.isTie && <div className='battle-tied'> Tied</div>}
                            {vote2 && <div className='voted'><img src={VodetIcon} alt='Voted' /></div>}
                            {
                                battleDetail?.battleOpponentStatus === true ?
                                    <img className={`${winner && winner?._id === battleDetail?.opponentUsersDetails?.[0]?._id ? 'winner-border' : ''}`} src={battleDetail?.opponentUsersDetails?.[0]?.image ? battleDetail?.opponentUsersDetails?.[0]?.image : UserImg} />
                                    :
                                    challengeOpponentUser ? <img src={challengeOpponentUser?.image} /> :
                                        <img src={AwaitingOppImg} />
                            }

                        </div>


                        {battleDetail?.battleOpponentStatus == true && <div className='battle-author-detail'>
                            <div className='author-name'>{battleDetail?.opponentUsersDetails?.[0]?.name ? battleDetail?.opponentUsersDetails?.[0]?.name : battleDetail?.opponentUsersDetails?.[0]?.userName}</div>
                            <div className='author-song-title'>{battleDetail?.opponentTrackDetails?.[0]?.track_name}</div>
                        </div>
                        }

                        {battleDetail?.battleOpponentStatus == false && <div className='battle-author-detail'>

                            <div className='author-name'>{challengeOpponentUser ? challengeOpponentUser?.name : 'Awaiting Opponent'}</div>

                        </div>
                        }
                    </div>
                    <div className='vs-wrap'><VSIcon /></div>
                </div>
                <div className='battle-wrapper-bottom'>
                    {battleDetail?.battleOpponentStatus && <div className='battle-info-wrap'>
                        {/* {moment(battleDetail?.battleEndDate).isBefore(moment()) ?
                            <div className='battle-info-title'>Ended : </div>
                            :
                            <div className='battle-info-title'>Time remaining:</div>
                        }

                        <div className={`battle-info ${danger <= 1 && !moment(battleDetail?.battleEndDate).isBefore(moment()) ? 'danger' : ''}`}>
                            {trending && <div className="icon-wrap">
                                <img src={FireIcon} alt='Fire' />
                            </div>
                            } {danger && danger <= 1 ? Math.floor((danger * 60)) + ' minutes' : timer} </div>

                        {moment(battleDetail?.battleEndDate).isBefore(moment()) &&
                            <div className='battle-info-title'>Prize : {battleDetail?.battleEnteryFee * 2} Clout</div>
                        } */}
                        <div className='battle-info-title'>
                            <img src={MusicSvg} style={{ width: 15, height: 15 }} />{`  `}
                            {battleDetail?.battleTrackGenre?.[0]?.label}
                        </div>
                        <div className='battle-info-title'>
                            <img src={RewardSvg} style={{ width: 15, height: 15 }} />{`  `}
                            {battleDetail?.battleEnteryFee}{`  `}
                            <img src={CloutSvg} style={{ width: 12, height: 12 }} />

                        </div>

                    </div>
                    }

                    {battleDetail?.battleOpponentStatus == false && <div className='battle-info-wrap'>
                        <div className='battle-info-title'>
                            <img src={MusicSvg} style={{ width: 15, height: 15 }} />{`  `}
                            {battleDetail?.battleTrackGenre?.[0]?.label}

                        </div>
                        <div className='battle-info-title'>
                            <img src={RewardSvg} style={{ width: 15, height: 15 }} />
                            {`  `}
                            {battleDetail?.battleEnteryFee}{`  `}<img src={CloutSvg} style={{ width: 12, height: 12 }} /></div>
                    </div>
                    }
                    {battleDetail?.battlecreatedBy !== userId && battleDetail?.opponentcreatedBy !== userId && type !== 'awaiting' && !moment(battleDetail?.battleEndDate).isBefore(moment()) &&
                        <div className='btn-wrap'>
                            <Button style={{ height: 40, minWidth: 135, maxWidth: 135, flexWrap: 'wrap', backgroundColor: '#F2059F' }} variant='secondary' onClick={handleVote}>


                                <div style={{ fontSize: 14, display: 'block', textAlign: 'center', marginTop: -5 }}>Vote</div>
                                <div style={{ fontSize: 9, display: 'block', textAlign: 'center', marginTop: -5, display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2 }}>
                                    <img src={ClockSvg} style={{
                                        width: 12,
                                        height: 12,
                                        marginTop: 5,
                                        marginRight: 2
                                    }} />{`  `}
                                    {timer}
                                </div>

                            </Button></div>}
                    {battleDetail?.battleOpponentStatus == false && userId !== battleDetail?.battlecreatedBy &&

                        <div className='btn-wrap'><Button disabled={battleDetail?.battleTrackType === 0 && (challengeOpponentUser && challengeOpponentUser?._id !== userId)} variant='secondary'
                            style={{
                                backgroundColor: '#05C6FE',
                                color: 'black'
                            }} >Join</Button></div>}
                    {battleDetail?.battleOpponentStatus == false && userId === battleDetail?.battlecreatedBy && <div className='btn-wrap'><Button variant='secondary' >Withdraw</Button></div>}
                    {moment(battleDetail?.battleEndDate).isBefore(moment()) &&
                        <div className='btn-wrap'><Button variant='secondary' style={{
                            backgroundColor: '#D39F01'
                        }}>Results</Button></div>
                    }
                </div>
            </div >
        </>
    )
}

export default BattleCard